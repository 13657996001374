import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { loadOpds } from "libs/files";
import Rarity from "components/Rarity";
import Header from "components/Header";
import Footer from "components/Footer";
import ProgressiveImage from "components/ProgressiveImage";
import empty from "images/empty.svg";
import loadingLogo from "images/logo-infini-black-beta.png";
import "styles/loader-animation.css";

type LibraryType = {
  status: string;
  books: any;
};

export default function Library() {
  const { t, i18n } = useTranslation();

  const [library, setLibrary] = useState<LibraryType>({ status: "loading", books: [] });
  const [id, setId] = useState<string>("");

  useEffect(() => {
    async function getLibrary() {
      const currentUser = await Auth.currentUserInfo();
      setId(currentUser.attributes["custom:blockchain_address"]);

      const books = await loadOpds(currentUser.attributes["custom:blockchain_address"]);
      setLibrary(books);
    }
    getLibrary();
    /*
    document.title = t("local.library.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
    */
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      {library.status === "complete" && (
        <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
          <div className="container mx-auto py-16">
            <div className="mt-6 grid grid-col-1 gap-y-10 gap-x-6 md:grid-cols-3 md:gap-20 lg:grid-cols-4 lg:gap-20 xl:grid-cols-5 2xl:grid-cols-6">
              {library.books.map((book: any) => (
                <div key={book.nft.id} className="group relative">
                  <div className="w-full bg-gray-200 aspect-w-1 aspect-h-1 rounded-sm drop-shadow-md shadow-ct-darkgrey overflow-hidden hover:opacity-75 hover:scale-105">
                    <Link to={`/book/${book.nft.id}`}>
                      <ProgressiveImage
                        src={`https://s3.amazonaws.com/${process.env.REACT_APP_AWS_BUCKET as string}/protected/${id}/${book.link[0].href}`}
                        alt={book.title}
                        classes="w-full h-full object-center object-cover lg:w-full lg:h-full"
                      />
                    </Link>
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-base font-bold text-gray-900">{book.title || "Untitled"}</h3>
                      <p className="text-sm text-gray-900">{book["dc:publisher"]}</p>
                      <p className="mt-0.5 text-xs text-gray-900 align-baseline">
                        {book.nft.rarity &&
                        <Rarity rarity={book.nft.rarity} language={i18n.resolvedLanguage!} size="small" valign="align-bottom" />} #{book.nft.mint}{" "}
                        {t("local.library.label.of")} {book.nft.maximum}
                      </p>
                    </div>
                    <p className="text-lg font-medium text-gray-900">
                      <Link className="hover:text-ct-yellow" to={`/details/${book.nft.id}`}>
                        <i className="icon-info"></i>
                      </Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      )}
      {library.status === "empty" && (
        <main className="flex-grow bg-ct-lightgrey px-4 py-16 md:px-0">
          <img alt={t("local.library.label.altimg_empty")} className="mx-auto h-96 mb-16" src={empty} />
          <h1 className="text-center text-3xl text-ct-yellow">{t("local.library.label.altimg_empty")}</h1>
          <p className="max-w-3xl mx-auto text-center">{t("local.library.content.empty.first")}</p>
        </main>
      )}
      {library.status === "loading" && (
        <main className="flex-grow bg-ct-lightgrey px-4 py-16 md:px-0">
          <img className="animation mx-auto w-1/2 mt-4" src={loadingLogo} alt={t("local.library.label.loading")} />
        </main>
      )}
      <Footer />
    </div>
  );
}
