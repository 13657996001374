import { useTranslation } from "react-i18next";

function TextInput({
  handleChange,
  name,
  value,
  label,
  pattern,
  readonly
}: {
  handleChange: () => void;
  name: string;
  value: string;
  label: string;
  pattern: string;
  readonly: boolean;
}) {
  return (
    <div className="relative">
      <input
        id={name}
        className="input__field w-full text-sm pt-5 pb-2 px-3 z-0 border border-ct-grey focus:outline-none focus:border-ct-yellow focus:shadow-inner-1 focus:shadow-ct-yellow"
        placeholder=" "
        data-testid={name}
        type="text"
        onChange={handleChange}
        value={value}
        pattern={pattern}
        readOnly={readonly}
      />
      <label htmlFor={name} className="input__label absolute left-3 translate-y-2/3 text-sm text-ct-grey">
        {label}
      </label>
    </div>
  );
}

function EmailInput({ handleChange, value, label }: { handleChange: () => void; value: string; label: string }) {
  return (
    <div className="relative">
      <input
        id="email"
        className="input__field w-full text-sm pt-5 pb-2 px-3 z-0 border border-ct-grey focus:outline-none focus:border-ct-yellow focus:shadow-inner-1 focus:shadow-ct-yellow"
        placeholder=" "
        data-testid="email"
        type="email"
        onChange={handleChange}
        value={value}
      />
      <label htmlFor="email" className="input__label absolute left-3 translate-y-2/3 text-sm text-ct-grey">
        {label}
      </label>
    </div>
  );
}

function PasswordInput({
  handleChange,
  handleShow,
  value,
  label,
  visible,
  id
}: {
  handleChange: () => void;
  handleShow: () => void;
  value: string;
  label: string;
  visible: boolean;
  id: string | undefined;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex relative w-full">
      <input
        id={id || "password"}
        className="input__field w-full text-sm pt-5 pb-2 px-3 z-0 border border-ct-grey focus:outline-none focus:border-ct-yellow focus:shadow-inner-1 focus:shadow-ct-yellow"
        placeholder=" "
        data-testid={id || "password"}
        type={visible ? "text" : "password"}
        onChange={handleChange}
        value={value}
        autoComplete="off"
      />
      <label htmlFor={id || "password"} className="input__label absolute left-3 translate-y-2/3 text-sm text-ct-grey">
        {label}
      </label>
      <div className="absolute inset-y-0 right-0 flex items-center px-2">
        <label className="bg-ct-yellow hover:bg-ct-darkgrey rounded px-2 py-1 text-sm text-white font-mono cursor-pointer">
          <input className="hidden" type="checkbox" onClick={handleShow} />
          {visible ? t("global.button.hide") : t("global.button.show")}
        </label>
      </div>
    </div>
  );
}

function SelectLanguage({
  languages,
  active,
  handleChange
}: {
  languages: [{ value: string; label: string }];
  active: string;
  handleChange: () => void;
}) {
  return (
    <select
      defaultValue={active}
      className="appearance-none hover:form-select transition text-sm py-2 px-2 -ml-2 -mb-2 w-1/2 border border-transparent bg-transparent hover:bg-white hover:border-ct-grey focus:outline-none focus:border-ct-yellow focus:shadow-inner-1 focus:shadow-ct-yellow blur:border-0"
      onChange={handleChange}
    >
      {languages.map((language) => (
        <option value={language.value} key={language.value}>
          {language.label}
        </option>
      ))}
    </select>
  );
}

function SubmitButton({ disabled, loading, label }: { disabled: boolean; loading: boolean; label: string }) {
  return (
    <button
      type="submit"
      id="submit"
      data-testid="submit"
      className="transition bg-ct-yellow text-white flex items-center text-sm uppercase tracking-wide p-3.5 hover:bg-ct-darkgrey disabled:bg-ct-grey disabled:cursor-not-allowed"
      disabled={disabled}
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 inline-block flex-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <div className="flex-1">{label}</div>
      )}
    </button>
  );
}

function ClickButton({
  disabled,
  handleClick,
  id,
  label,
  loading,
  colour
}: {
  disabled: boolean;
  handleClick: any;
  id: string;
  label: string;
  loading: boolean;
  colour?: string;
}) {
  return (
    <button
      type="button"
      id={id}
      data-testid={id}
      onClick={handleClick}
      className={`transition ${
        colour || "bg-black"
      } text-white flex items-center text-sm uppercase tracking-wide p-3.5 hover:bg-ct-darkgrey disabled:bg-ct-grey disabled:cursor-not-allowed`}
      disabled={disabled}
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 inline-block flex-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <div className="flex-1">{label}</div>
      )}
    </button>
  );
}

function LinkButton({
  size,
  label,
  actionIcon,
  completeIcon,
  handleClick,
  disabled
}: {
  size: string;
  label: string;
  actionIcon: string;
  completeIcon: string;
  handleClick: () => void;
  disabled: boolean;
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`flex justify-between items-center bg-white ${size} p-3.5 border border-ct-grey ${actionIcon && "hover:border-ct-yellow"}`}
      onClick={handleClick}
    >
      <span className="block">
        {completeIcon && <i className={`${completeIcon} text-green-600`}></i>}&nbsp;{label}
      </span>{" "}
      {actionIcon && <i className={`${actionIcon} block`}></i>}
    </button>
  );
}

export { ClickButton, EmailInput, LinkButton, PasswordInput, SelectLanguage, SubmitButton, TextInput };
