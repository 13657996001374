import { useState, useEffect, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { NavLink } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { SubmitButton, EmailInput, PasswordInput } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";

export default function Login() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { userHasAuthenticated, setSession } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  useEffect(() => {
    document.title = t("local.login.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  }, [t]);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const session = await Auth.signIn(fields.email, fields.password);
      setSession!(session);

      if (session.challengeName === "SOFTWARE_TOKEN_MFA") {
        setIsLoading(false);
        navigate(`/login/mfa${location.search}`);
      } else {
        setIsLoading(false);
        userHasAuthenticated!(true);
      }
    } catch (e: any | unknown) {
      if (e.code === "UserNotConfirmedException") {
        navigate("/signup/verification", { state: { username: fields.email } });
      } else if (e.code === "PasswordResetRequiredException") {
        navigate("/login/confirm", { state: { email: fields.email } });
      }
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.login.title.opensession")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.login.subtitle.info")} </p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <EmailInput handleChange={handleFieldChange} value={fields.email} label={t("global.label.email")} />
          <PasswordInput
            handleChange={handleFieldChange}
            handleShow={() => setShowPassword(!showPassword)}
            visible={showPassword}
            value={fields.password}
            label={t("global.label.password")}
            id={undefined}
          />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.login")} />
          <p>
            <NavLink className="transition text-ct-yellow hover:opacity-75" to="/login/forgot">
              {t("local.login.link.forgotpass")}
            </NavLink>
          </p>
          {errorMessage}
        </form>
      </main>
      <Footer />
    </div>
  );
}
