import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import ReaderApi, { IReadingProgressData } from "../colibrio/ReaderApi";
import { saveBookSettings } from "libs/files";
import { INavigationIntentEngineEvent } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";

export type ReaderNavigationProps = { readerApi: ReaderApi; settings: object; bookFile: string };

const DefaultReadingProgressData: IReadingProgressData = {
  startPosition: 0,
  endPosition: 0,
  length: 0
};

const ReaderNavigation = ({ readerApi, settings, bookFile }: ReaderNavigationProps) => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const [readingProgressData, setReadingProgressData] = useState<IReadingProgressData>(DefaultReadingProgressData);
  const [sliderMaxValue, setSliderMaxValue] = useState(1);
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const handleLink = (navigationIntent: INavigationIntentEngineEvent) => {
    if(navigationIntent.internalNavigation === false) {
      window.open(navigationIntent.locator.toUrl().href, '_blank');
    }
  }

  useEffect(() => {
    async function cleanup() {
        const currentUser = await Auth.currentUserInfo();
        setAddress(currentUser.attributes["custom:blockchain_address"]);
      saveBookSettings(bookFile, currentUser.attributes["custom:blockchain_address"], {
        location: readerApi.readerView.getReadingPosition(),
        bookmarks: null,
        annotations: null
      });
    }
    window.onpopstate = e => {
      cleanup();
    };
  });

  useEffect(() => {
    readerApi.readerView.addEngineEventListener('navigationIntent', handleLink);
    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (!e.isTrusted) return;
      if (e.key === "ArrowRight") readerApi.next();
      if (e.key === "ArrowLeft") readerApi.previous();
    });
    const updateProgress = (newProgressData: IReadingProgressData): void => {
      setReadingProgressData(newProgressData);
      if (isValueInRange(sliderPosition, newProgressData.startPosition, newProgressData.endPosition)) {
        //Update if out of range, so that slider position doesn't jump around
        setSliderPosition(newProgressData.startPosition);
      }
      setSliderMaxValue(newProgressData.length);
    };

    readerApi.setReadingProgressCallback(updateProgress);
    return () => {
      readerApi.removeReadingProgressCallback();
    };
  }, [readerApi, sliderPosition]);

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newProgress = parseInt(e.target.value);
    //To update position immediately
    setSliderPosition(newProgress);
    if (isValueInRange(newProgress, readingProgressData.startPosition, readingProgressData.endPosition)) {
      //Navigate if range is outside visible range
      readerApi.navigateToReadingProgressValue(newProgress);
    }
  };

  const isValueInRange = (progressPosition: number, start: number, end: number): boolean => {
    return progressPosition < start || progressPosition > end;
  };

  const calculateProgressPercentage = (progress: number): number => {
    return (progress / sliderMaxValue) * 100;
  };

  const handleQuit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const currentUser = await Auth.currentUserInfo();
    saveBookSettings(bookFile, currentUser.attributes["custom:blockchain_address"], {
      location: readerApi.readerView.getReadingPosition(),
      bookmarks: null,
      annotations: null
    });
    navigate("/");
  };

  return (
    <div className="w-full flex items-center px-2 my-2">
      <button className="block mx-4 hover:text-ct-yellow" onClick={handleQuit}>
        <i className="icon-logout"></i>
      </button>
      <button className="block" onClick={readerApi.previous}>
        <i className="icon-arrow-left"></i>
      </button>
      <input
        style={{ backgroundSize: calculateProgressPercentage(sliderPosition) + "% 100%" }}
        className="block grow mx-4"
        type="range"
        id="slider"
        min={0}
        max={sliderMaxValue}
        value={sliderPosition}
        onChange={handleSliderChange}
      />
      <button className="block" onClick={readerApi.next}>
        <i className="icon-arrow-right"></i>
      </button>
    </div>
  );
};
export default ReaderNavigation;
