import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth, API } from "aws-amplify";
import { bookFromOpds } from "libs/files";
import { onError } from "libs/error";
import Header from "components/Header";
import Footer from "components/Footer";
import Rarity from "components/Rarity";
import Modal from "components/Modal";
import { ClickButton } from "components/FormElements";
import ProgressiveImage from "components/ProgressiveImage";
import fingerprint from "images/finger-print-icon.svg";
import locked from "images/locked.svg";
import unlocked from "images/unlocked.svg";

export default function Details() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [book, setBook] = useState<any>(null);
  const [identity, setIdentity] = useState<string>("");
  const [user, setUser] = useState<any>({});
  const [status, setStatus] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);
  const [showModal, setShowModal] = useState(false);

  const truncateString = (str: string) => {
    var truncated = str.slice(0, 10) + "…" + str.substring(str.length - 10);
    return truncated;
  };

  useEffect(() => {
    async function getBook() {
      const currentUser = await Auth.currentUserInfo();
      setIdentity(currentUser.attributes["custom:blockchain_address"]);
      setUser(currentUser.attributes);
      const book = bookFromOpds(id!);
      setBook(book);
      try {
        const status = await API.post('activations', '/activations/status/'+id, {
          body: {
            address: currentUser.attributes["custom:blockchain_address"]
          }
        });
        setStatus(status);
      } catch (e:any) {
        const errorHandler = onError(e);
        if (e.response.status === 401) navigate("/404");
        setErrorMessage(errorHandler);
        setIsLoading(false);
      }
      document.title = t("local.bookdetail.title.page") + " - " + book.title + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
    }
    getBook();
  }, [id, t]);

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
  
  function convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = minutes % 60;
  
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds,
    )}`;
  }

  async function toggleLock(event: React.MouseEvent<HTMLInputElement>) {
    event.preventDefault();
    setShowModal(false);
    setIsLoading(true);
    try {
      const toggle = await API.post('activations', '/activations/toggle/'+id, {
        body: {
          email: user.email,
          language: user["custom:preferred_language"],
          address: user["custom:blockchain_address"]
        }
      });
      setStatus(toggle);
      setIsLoading(false);
    } catch (e:any) {
      const errorHandler = onError(e);
      if (e.response.status === 401) navigate("/404");
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4">
        {book && status && (
          <section className="container my-12 max-w-5xl mx-auto grid grid-cols-1 gap-12 md:grid-cols-2">
            <div className="rounded-sm drop-shadow-lg shadow-ct-darkgrey overflow-hidden">
              <ProgressiveImage
                src={`https://s3.amazonaws.com/${process.env.REACT_APP_AWS_BUCKET as string}/protected/${identity}/${book.link[0].href}`}
                alt={book.title}
              />
            </div>
            <div>
              <h1 className="text-5xl text-ct-yellow ">{book.title}</h1>
              <p className="text-ct-text font-light border-b border-ct-white mb-6 pb-6">
                {t("global.label.by")}{" "}
                {book.author
                  ? book.author
                      .map((item: any) => {
                        return item.name;
                      })
                      .join(", ")
                  : t("local.bookdetail.label.unknown")}
              </p>
              <div className="grid grid-cols-4 gap-4">
                <div className="bg-ct-white drop-shadow-md rounded p-2 col-span-2">
                  <h3 className="text-sm text-ct-text pb-2">{t("local.bookdetail.item.rarity")}</h3>
                  <div>
                    <Rarity rarity={book.nft.rarity} language={i18n.resolvedLanguage!} size="regular" />
                  </div>
                </div>
                <div className="bg-white drop-shadow-md rounded p-2">
                  <h3 className="text-sm text-ct-text text-center pb-2">{t("local.bookdetail.item.runsize")}</h3>
                  <div className="text-xl font-bold text-ct-text text-center">{book.nft.maximum}</div>
                </div>
                <div className="bg-white drop-shadow-md rounded p-2">
                  <h3 className="text-sm text-ct-text text-center pb-2">{t("local.bookdetail.item.tolkennum")}</h3>
                  <div className="text-xl font-bold text-ct-yellow text-center">{book.nft.mint}</div>
                </div>
              </div>
              <div className="py-6 border-b border-ct-white">
                <h2 className="text-lg">
                  <img alt="{book.title}" className="h-6 inline text-ct-text" src={fingerprint} /> {t("local.bookdetail.section.nftdetails")}
                </h2>
                <p>
                  <span className="text-sm text-ct-text ">{t("local.bookdetail.item.tokenid")}:</span>{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.REACT_APP_POLYGON_TOKEN + book.nft.contract + "?a=" + book.nft.id}
                    className="font-bold text-ct-yellow"
                  >
                    #{book.nft.id}
                  </a>
                </p>
                <p>
                  <span className="text-sm text-ct-text ">{t("local.bookdetail.item.contractid")}:</span>{" "}
                  <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_POLYGON_TOKEN + book.nft.contract} className="font-bold text-ct-yellow">
                    {truncateString(book.nft.contract)}
                  </a>
                </p>
              </div>
              <h2 className="text-lg pt-6">{t("local.bookdetail.section.description")}</h2>
              <p
                className="text-base text-ct-text font-light pb-6"
                dangerouslySetInnerHTML={{ __html: decodeURI(book.content["#text"].replaceAll("&#xD;", "<br>")) }}
              ></p>
              <h2 className="text-lg">{t("local.bookdetail.section.additionaldetails")}</h2>
              <table className="table-auto mb-6">
                <tbody>
                  {book.contributor && (
                    <tr>
                      <th className="pr-6 text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.contributors")}:</th>
                      <td>
                        {book.contributor
                          .map((item: any) => {
                            return item.name;
                          })
                          .join(", ")}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.language")}:</th>
                    <td className="uppercase">{book["dc:language"].substring(0, 2)}</td>
                  </tr>
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.isbn")}:</th>
                    <td>{book["dc:identifier"][0].split(":")[2]}</td>
                  </tr>
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.publisher")}:</th>
                    <td>{book["dc:publisher"]}</td>
                  </tr>
                </tbody>
              </table>
              <h2 className="text-lg">{t("local.bookdetail.section.transactional")}</h2>
              <h3 className="text-ct-text">
                {t("local.bookdetail.section.trade")}
              </h3>
              <p className="text-sm mb-2">
                Current Status:&nbsp;
                {status.status === "locked" ?
                <img alt={status.status!} className="h-6 inline text-ct-text" src={locked} />
                :
                <img alt={status.status!} className="h-6 inline text-ct-text" src={unlocked} />
                }
                {status.status === "time_unlocked" &&
                  <span>&nbsp;{t("for")} {convertMsToTime(status.remaining)}</span>
                }
              </p>
              {status.status === "locked" ?
                <ClickButton id="unlock" handleClick={() => setShowModal(true)} disabled={isLoading} loading={isLoading} label={t("local.bookdetail.link.unlock")} />
              :
                <ClickButton id="lock" handleClick={() => setShowModal(true)} disabled={isLoading || status.status === "time_unlocked"} loading={isLoading} label={t("local.bookdetail.header.nftlock")} />
              }
              {errorMessage}
              <h2 className="text-xl pt-6">{t("global.label.navigation")}</h2>
              <Link to="/" className="block text-sm text-ct-yellow hover:text-black mb-2">
                {t("global.link.returntolibrary")}
              </Link>
            </div>
          </section>
        )}
        {showModal && (
          <Modal
            title={status.status === "locked" ? t("local.bookdetail.header.nftunlocked") : t("local.bookdetail.header.nftlock")}
            subtitle={status.status === "locked" ? t("local.bookdetail.prompt.nftunlock") : t("local.bookdetail.prompt.nftlock")}
            clickLabel={t("global.button.continue")}
            cancelLabel={t("global.button.cancel")}
            handleClick={toggleLock}
            handleCancel={() => setShowModal(false)}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}
