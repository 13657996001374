import React from "react"
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "context/AppContext";
import { ClickButton, SelectLanguage } from "components/FormElements";
import { onError } from "libs/error";
import Header from "components/Header";
import SetupMFA from "components/SetupMultiFactorAuth";
import Footer from "components/Footer";
import Modal from "components/Modal";
import fingerprint from "images/finger-print-icon.svg";

export default function Account() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { user, userHasAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [mfaConfigured, setMfaConfigured] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);
  const [showSetupMfa, setShowSetupMfa] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user.preferredMFA === "SOFTWARE_TOKEN_MFA") {
      setMfaConfigured(true);
    }
    if (user.attributes.email_verified) {
      setEmailVerified(true);
    }
    document.title = t("local.account.title.page") + " - " + t("global.system.name");
    if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
      document.title += " | " + process.env.REACT_APP_VERSION;
    }
  }, [user, t]);

  async function handleLogout() {
    setIsLoading(true);
    await Auth.signOut();
    userHasAuthenticated(false);
    setIsLoading(false);

    navigate("/login");
  }

  const handleLanguageChange = async (event) => {
    event.preventDefault();
    setChangeLanguage(true);

    try {
      await Auth.updateUserAttributes(user, { "custom:preferred_language": event.target.value });
      i18n.changeLanguage(event.target.value);
      setChangeLanguage(false);
      document.title = t("local.account.title.page") + " - " + t("global.system.name");
      if (process.env.REACT_APP_VERSION !== "OFFICIAL") {
        document.title += " | " + process.env.REACT_APP_VERSION;
      }
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setChangeLanguage(false);
    }
  };

  async function disableMFA(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.setPreferredMFA(user, "NOMFA");
      setIsLoading(false);
      setMfaConfigured(false);
      setShowModal(true);
    } catch (e) {
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className={`min-h-screen flex flex-col ${changeLanguage && "blur-sm"}`}>
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.account.title.main")}</h2>
        <p className="text-base text-black text-left mb-6 max-w-2xl mx-auto">{t("local.account.section.main")}</p>
        <div className="flex flex-col space-y-6 mb-20">
          <section className="container mx-auto max-w-md mt-6">
            <h3 className="text-xl mb-1">{t("local.account.label.email")}</h3>
            <p className="text-sm">
              {user.attributes.email}{" "}
              {(user.attributes.email_verified || emailVerified) && (
                <span className="text-xs text-white bg-green-600 rounded-full px-2 py-0.5">{t("global.label.verified")}</span>
              )}
            </p>
          </section>
          <section className="container mx-auto max-w-md">
            <h3 className="text-xl mb-1">{t("local.account.label.blockchain")}</h3>
            {user.attributes["custom:blockchain_address"] ? (
              <p>
                <a
                  className="text-sm flex items-center text-ct-yellow hover:text-black"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_POLYGON_ADDRESS + user.attributes["custom:blockchain_address"] + "#tokentxnsErc1155"}
                >
                  <img alt="fingerprint" className="h-5 block mr-2.5" src={fingerprint} />
                  <span className="block truncate">{user.attributes["custom:blockchain_address"]}</span>
                </a>
              </p>
            ) : (
              <ClickButton
                handleClick={() => navigate("/signup/blockchain")}
                disabled={isLoading}
                label={t("local.account.label.generateblockchain")}
              />
            )}
          </section>
          <section className="container mx-auto max-w-md">
            <h3 className="text-xl">{t("local.account.label.language")}</h3>
            <SelectLanguage
              languages={[
                { label: "English", value: "en" },
                { label: "Français", value: "fr" }
              ]}
              active={user.attributes["custom:preferred_language"] || i18n.resolvedLanguage}
              handleChange={handleLanguageChange}
            />
            {errorMessage}
          </section>
          <section className="container mx-auto max-w-md">
            <h3 className="text-xl mb-1">{t("local.account.label.manageaccount")}</h3>
            <Link to="/account/change-password" className="block text-sm text-ct-yellow hover:text-black">
              {t("local.account.link.changepassword")}
            </Link>
            {emailVerified ? (
              <Link to="/account/change-email" className="block text-sm text-ct-yellow hover:text-black">
                {t("local.account.link.changeemail")}
              </Link>
            ) : (
              <Link to="/account/verify" className="block text-sm text-ct-yellow hover:text-black">
                {t("local.account.changepass.title.verifyemail")}
              </Link>
            )}
          </section>
          <section className="container mx-auto max-w-md">
            <h3 className="text-xl">{t("local.account.label.signinsecurity")}</h3>
            <p className="text-sm mb-2">{mfaConfigured ? t("local.account.label.2fa_active") : t("local.account.label.notconfigured")}</p>
            {mfaConfigured ? (
              <ClickButton handleClick={disableMFA} disabled={isLoading} label={t("local.account.2fa.radio.deactivate")} />
            ) : (
              <ClickButton handleClick={() => setShowSetupMfa(true)} disabled={isLoading} label={t("local.account.2fa.radio.configure")} />
            )}
          </section>
          <section className="container mx-auto max-w-md">
            <h3 className="text-xl mb-1">{t("global.label.navigation")}</h3>
            <Link to="/" className="block text-sm text-ct-yellow hover:text-black mb-2">
              {t("global.link.returntolibrary")}
            </Link>
            <ClickButton handleClick={handleLogout} disabled={isLoading} label={t("global.button.signout")} />
          </section>
        </div>
        {showSetupMfa && <SetupMFA handleHide={() => setShowSetupMfa(false)} returnStatus={setMfaConfigured} />}
        {showModal && (
          <Modal
            title={t("local.account.2fa.title.confirmation")}
            subtitle={t("local.account.2fa.subtitle.confirmation")}
            clickLabel={t("global.button.continue")}
            handleClick={() => setShowModal(false)}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}
