import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "context/AppContext";
import { NavLink } from "react-router-dom";
import Logo from "components/Logos";

export default function Header() {
  const { t, i18n } = useTranslation();
  const { isAuthenticated } = useAppContext();
  const [showMenu, setShowMenu] = useState(false);

  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="flex-shrink-0 bg-white text-white shadow-ct-darkgrey drop-shadow-md">
      <nav className="container mx-auto flex flex-wrap items-center justify-between w-full py-4 text-black px-4 lg:px-0">
        <div className="inset-y-0 left-0 flex items-center lg:hidden">
          <button
            onClick={handleOpenMenu}
            className="inline-flex items-center justify-center hover:text-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <i className={`${!showMenu ? "icon-menu" : "icon-close"} cursor-pointer block h-6 w-6 lg:hidden`}></i>
            <span className="sr-only">Open main menu</span>
          </button>
        </div>
        <div className="flex-1 flex items-center justify-center lg:justify-start">
          <figure className="flex-shrink-0 flex items-center">
            <Logo logo="CompanyHeader" language={i18n.resolvedLanguage!} imgClass="h-16 w-auto lg:h-24" altText={t("global.system.logo")} />
          </figure>
          <div className="hidden lg:block lg:ml-6">
            <div className="flex space-x-4 font-display">
              <NavLink to="/" className="transition text-black px-3 py-2 text-sm uppercase text-shadow hover:text-white">
                {t("global.link.home")}
              </NavLink>
              <a
                className="transition px-3 py-2 text-sm text-black text-shadow uppercase hover:text-white"
                href={process.env.REACT_APP_URL_SITE_COMPANY}
              >
                {t("global.link.website")}
              </a>
              <a
                className="transition px-3 py-2 text-sm uppercase text-black text-shadow hover:text-white"
                href={process.env.REACT_APP_URL_SITE_REDEEM}
              >
                {t("global.link.redeem")}
              </a>
              <a className="transition px-3 py-2 text-sm uppercase text-black text-shadow hover:text-white" href={process.env.REACT_APP_URL_PAGE_FAQ}>
                {t("global.link.faq")}
              </a>
              <a
                className="transition px-3 py-2 text-sm uppercase text-black text-shadow hover:text-white"
                href={process.env.REACT_APP_URL_PAGE_CONTACT}
              >
                {t("global.link.contactus")}
              </a>
            </div>
          </div>
        </div>
        <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          {isAuthenticated && (
            <NavLink
              to="/account"
              className={({ isActive }) =>
                isActive
                  ? "text-white block cursor-default py-3 text-shadow text-2xl md:text-lg"
                  : "transition text-black block py-3 text-shadow text-2xl hover:text-white md:text-lg"
              }
            >
              <i className="icon-user"></i>
            </NavLink>
          )}

          {!isAuthenticated && (
            <select
              onChange={(event) => i18n.changeLanguage(typeof event.target.value === "string" ? event.target.value : "en")}
              defaultValue={i18n.language.substring(0, 2)}
              className="transition border-none bg-transparent appearance-none outline-none text-black text-shadow font-display uppercase text-xs hover:opacity-70 hover:cursor-pointer focus:ring-0 "
            >
              <option value="en">en</option>
              <option value="fr">fr</option>
            </select>
          )}
        </div>
      </nav>
      <div className={`${!showMenu ? "hidden" : "block"} h-screen bg-black overflow-hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <NavLink to="/" className="text-white uppercase block px-3 py-2 hover:text-ct-yellow">
            {t("global.link.home")}
          </NavLink>
          <a className="text-white uppercase block px-3 py-2 hover:text-ct-yellow" href={process.env.REACT_APP_URL_SITE_COMPANY}>
            {t("global.link.website")}
          </a>
          <a className="text-white uppercase block px-3 py-2 hover:text-ct-yellow" href={process.env.REACT_APP_URL_SITE_REDEEM}>
            {t("global.link.redeem")}
          </a>
          <a className="text-white uppercase block px-3 py-2 hover:text-ct-yellow" href={process.env.REACT_APP_URL_PAGE_FAQ}>
            {t("global.link.faq")}
          </a>
          <a className="text-white uppercase block px-3 py-2 hover:text-ct-yellow" href={process.env.REACT_APP_URL_PAGE_CONTACT}>
            {t("global.link.contactus")}
          </a>
        </div>
      </div>
    </header>
  );
}
